import * as request from 'superagent';
import * as _ from 'lodash';
import {Table} from 'modules/table';

class ReportTable extends Table {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._tableType = 'report';
        console.log("creo un report!", element);

    }

    renderCallback() {
        if (this.chartbox) {
            this.chartbox.render();
            if (this.hide_col) {
                this.config.cols_to_hide.forEach(col_to_hide => {
                    this.hide_column(col_to_hide);
                    console.log(col_to_hide);
                });
            }
        }

        if (this.cache_key) {
            this.updateCache();
        }

    }

    hide_column(col_no) {
        var rows = this.element.getElementsByTagName('tr');
        for (var row = 0; row < rows.length; row++) {
            var cels = rows[row].getElementsByTagName('td')
            if (!cels || cels.length == 0) {
                var cels = rows[row].getElementsByTagName('th')
            }
            cels[col_no].style.display = "none";
        }
    }

    hideCol(col_no) {
        var col = document.getElementById("txtCol").value;
        if (isNaN(col) || col == "") {
            alert("Invalid Column");
            return;
        }
        col = parseInt(col, 10);
        col = col - 1;
        var tbl = document.getElementById("tblMain");
        if (tbl != null) {
            if (col < 0 || col >= tbl.rows.length - 1) {
                alert("Invalid Column");
                return;
            }
            for (var i = 0; i < tbl.rows.length; i++) {
                for (var j = 0; j < tbl.rows[i].cells.length; j++) {
                    tbl.rows[i].cells[j].style.display = "";
                    if (j == col)
                        tbl.rows[i].cells[j].style.display = "none";
                }
            }
        }
    }

    generateCell(cellData, row) {
        let existingCell = _.find(row.cells, {kpi: cellData.kpi_code});
        let cellContent = cellData.kpi_value_formatted.trim().replace(/(&nbsp;)/g,' - ');
        if (cellContent === '' || cellContent === '0.00' || cellContent === '0.00%' || cellContent === '0,00' || cellContent === '0,00%' || cellContent === '0') {
            // Mette il trattino nelle celle dei tabreport pari a 0 o vuote
            cellContent = this._emptyCellContent;
        }
        let cell = {
            type: cellData.kpi_measure_type,
            periodId: cellData.period__period_id,
            content: cellContent,
            value: parseFloat(cellData.kpi_value),
            kpi: cellData.kpi_code,
            class: cellData.class,
            analysisResult: cellData.kpi_analysis_result,
            order: _.isUndefined(existingCell) ? row.cells.length + 1 : existingCell.order,
            colSpan: 1,
            rowSpan: 1
        };
        if (this._emptyValues.includes(cell.content)) {
            cell.type = 'empty';
            cell.content = this._emptyCellContent;
        }
        return cell;
    }

    getCustomParams() {
        return {
            custom_view: 'get_json_for_report'
        };
    }

    setExtraConfig(extraConfig) {
        if (!_.isUndefined(extraConfig) && extraConfig.p_id) {
            this._period = extraConfig.p_id;
        }
    }

    addRow(rowData) {
        _.each(rowData.values, value => {
            if (parseInt(value.period__period_id) >= parseInt(this.config.period_to_hide_from)) {
                this.hide_col = true;
                return;
            }
        });

        let row = _.find(this.table.body, {id: rowData.row_id});
        if (_.isUndefined(row)) {
            row = this.generateRow(rowData);
            this.table.body.push(row);
        }
        _.each(rowData.values, cellData => {
            this.addCell(cellData, row.id);
        });
        this.updateGrid(row);
    }

}

export {ReportTable}